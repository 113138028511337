import {useWebView} from '@/common-pages/hooks/webview.hooks';
import theme from '@/style';
import React, {useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import GameSpin from '@/components/basic/gamespin';
import {envConfig, getUrlParams, goTo} from '@/utils';
import globalStore from '@/services/global.state';
import {homePage} from '@/config';
import {BasicObject} from '@/types';
import {useDebounce} from 'ahooks';
import qs from 'qs';
import {getGR8Token} from './service';
import {hideTabbar, showTabbar} from '@/components/utils/ui';
const Gr8 = ({
  onAuthFailed,
}: {
  singleAppPage?: boolean;
  onAuthFailed: () => void;
}) => {
  const [pageLoading, setPageLoading] = React.useState(true);
  const handleMessage = (data: string | BasicObject) => {
    if (typeof data === 'object') {
      if ((data as any).type === 'login.click') {
        console.log('login.click', data);
        goTo('Login', {
          backPage: homePage,
          sucessPage: homePage,
        });
        return;
      }
      if ((data as any).type === 'auth.fail') {
        console.error('auth.fail', data);
        if (window.location.pathname.includes('/sports')) {
          goTo('Login', {
            backPage: homePage,
            sucessPage: homePage,
          });
          return;
        }
        onAuthFailed();
      }
      if ((data as any).type === 'user.balance') {
        globalStore.updateAmount.next({});
        return;
      }
      if ((data as any).type === 'navigation') {
        if (window.location.pathname.includes('/sports')) {
          const queryStr = qs.stringify(data.query).trim();
          console.log('path', data.path);
          if (data.path.trim() === '/' || data.path.trim() === '') {
            showTabbar(true);
          } else {
            // 只能使用display: none来隐藏，否则会出现点击下注时顶出tabbar的高度问题
            hideTabbar(true);
          }
          window.history.replaceState(
            null,
            '',
            `${window.location.origin}/sports?path=${encodeURIComponent(
              `${data.path}${queryStr ? `?${queryStr}` : ''}`,
            )}`,
          );
        }
      }
    }
  };
  const firstEventsPath = useRef<string>();
  const nowRoutePath = useRef<string>();
  const [version, setVersion] = useState(0);
  const [token, setToken] = useState<string>();
  const debouncedToken = useDebounce(token, {wait: 500});
  const [sportToken, setSportToken] = useState<string>();
  const lastSportToken = useRef<string>();
  const [lang, setLang] = useState<string>();
  useEffect(() => {
    const urlParams = getUrlParams();
    console.log('urlParams', urlParams);
    if (!urlParams.path || urlParams.path.trim() === '/') {
      return;
    }
    if (
      urlParams.path.includes('/events') ||
      urlParams.path.includes('/promo')
    ) {
      firstEventsPath.current = urlParams.path;
    } else {
      nowRoutePath.current = urlParams.path;
      setVersion(v => v + 1);
    }
  }, []);
  useEffect(() => {
    if (sportToken == null) {
      return;
    }
    const _sportToken = sportToken === 'none' ? undefined : sportToken;
    if (lastSportToken.current && lastSportToken.current !== sportToken) {
      postMessage?.({
        type: 'iframe',
        value: {logout: true},
      });
    }
    lastSportToken.current = _sportToken;
    setTimeout(() => {
      console.log('nowRoutePath.current', nowRoutePath.current);
      const [path, query] = nowRoutePath.current?.split('?') || [];
      const json = qs.parse(query || '');
      json.jwt = _sportToken;
      const queryStr = qs.stringify(json);
      const url = `${envConfig.gr8IframeUrl}${
        lang?.split('_')[0].toLowerCase() || 'en'
      }${path || '/'}${queryStr ? `?${queryStr}` : ''}`;
      console.log('redirect url', url, Date.now());
      updateUrl?.(url);

      setTimeout(() => {
        console.log('firstEventsPath.current', firstEventsPath.current);
        if (firstEventsPath.current) {
          nowRoutePath.current = firstEventsPath.current;
          firstEventsPath.current = undefined;
          setVersion(v => v + 1);
        }
      }, 1000);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportToken, version, lang]);

  useEffect(() => {
    if (debouncedToken == null) {
      return;
    }
    const _token = debouncedToken === 'none' ? undefined : debouncedToken;

    if (_token) {
      getGR8Token().then(res => {
        setSportToken(res.token);
      });
    } else {
      setSportToken('none');
    }
  }, [debouncedToken]);

  const {postMessage, updateUrl, render} = useWebView({
    originUrl: ' ',
    onMessage: handleMessage,
    onLoadEnd: () => {
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    },
  });

  useEffect(() => {
    const sub = globalStore.tokenSubject.subscribe(_token => {
      setToken(_token || 'none');
    });
    const langSub = globalStore.langSubject.subscribe(_lang => {
      setLang(_lang);
    });
    const sportSub = globalStore.openSports.subscribe(_path => {
      if (_path != null) {
        nowRoutePath.current = _path;
        setVersion(v => v + 1);
      }
    });
    return () => {
      sub.unsubscribe();
      sportSub.unsubscribe();
      langSub.unsubscribe();
    };
  }, []);

  return (
    <View
      style={[theme.fill.fill, theme.flex.col, theme.background.lightGrey]}
      id="gr8">
      <GameSpin loading={pageLoading}>{render}</GameSpin>
    </View>
  );
};

export default Gr8;
