import {Theme} from '@react-navigation/native';
import {Appearance} from 'react-native';

enum ThemeType {
  deepRed, // 01 #e91100
  brightPurple, // 02 #9800f5
  brightGreen, // 03 #00b512
  deepCrimson, // 04 #d90000
}

type ThemeTypeKey = keyof typeof ThemeType;

/** 主题色及其衍生色 */
const basicColor: Record<ThemeTypeKey, Record<string, string>> = {
  deepRed: {},
  brightPurple: {},
  brightGreen: {},
  deepCrimson: {},
};

// const fontColor = {
//   main: '#000',
//   second: '#31373D',
//   accent: '#5F6975',
//   secAccent: '#9FA5AC',
//   grey: '#D8E0EA',
//   colorMyBets: '#374637',
// };

const useCustomerTheme = (themeType: ThemeTypeKey): Theme => {
  const dark = false && Appearance.getColorScheme() === 'dark';
  return {
    dark,
    colors: {
      primary: basicColor[themeType].primary,
      background: 'string',
      card: 'string',
      text: 'string',
      border: 'string',
      notification: 'string',
    },
  };
};

export default useCustomerTheme;
