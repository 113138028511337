import {Platform} from 'react-native';
import RNConfig from 'react-native-config';

const IS_WEB = Platform.OS === 'web';

const ENV_CONFIG = (IS_WEB ? process.env : RNConfig) as {
  REACT_APP_ENV: 'dev' | 'test' | 'uat' | 'prod' | 'local';
  REACT_APP_API_BASE_URL: string;
  REACT_APP_API_INDUSWIN_URL?: string;
  REACT_APP_API_SPORTS_URL?: string;
  REACT_APP_API_QUICK_URL?: string;
  REACT_APP_API_H5GAMES_URL?: string;
  REACT_APP_API_RACECAR_URL?: string;
  REACT_APP_API_LOTTO_URL?: string;
  REACT_APP_API_STATE_URL?: string;
  REACT_APP_IM_URL?: string;
  REACT_APP_BETBY_SCRIPT_URL?: string;
  REACT_APP_API_IM_URL?: string;
  REACT_APP_GR8_IFRAME_URL?: string;

  [k: string]: string | number | undefined;
};

class Config {
  private _IMURL: string;
  private _codePushKey: string;
  private _baseUrl: string;
  private _betbyScriptUrl: string;
  private _imApiUrl: string;
  private _gr8IframeUrl: string;
  get baseUrl() {
    return this._baseUrl;
  }
  private _induswinUrl: string | undefined;
  get induswinUrl() {
    return this._induswinUrl;
  }
  private _sportsUrl: string;
  get sportsUrl() {
    return this._sportsUrl;
  }
  private _quick3dUrl: string;
  get quickUrl() {
    return this._quick3dUrl;
  }
  private _racecarUrl: string;
  get racecarUrl() {
    return this._racecarUrl;
  }
  private _lottoUrl: string;
  get lottoUrl() {
    return this._lottoUrl;
  }
  private _stateUrl: string;
  get stateUrl() {
    return this._stateUrl;
  }
  private _reactH5Url = 'https://h5-games.pages.dev';
  get reactH5Url() {
    return this._reactH5Url;
  }
  get codePushKey() {
    return this._codePushKey;
  }
  get IMURL() {
    return this._IMURL;
  }
  private _moengageAppId = 'Q60RICPHDSGXDX7PXH3NCE7K';

  get moengageAppId() {
    return this._moengageAppId;
  }
  get betbyScriptUrl() {
    return this._betbyScriptUrl;
  }
  get gr8IframeUrl() {
    return this._gr8IframeUrl;
  }

  get imApiUrl() {
    return this._imApiUrl;
  }

  constructor() {
    this._baseUrl = ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._induswinUrl =
      ENV_CONFIG.REACT_APP_API_INDUSWIN_URL ||
      ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._reactH5Url = ENV_CONFIG.REACT_APP_API_H5GAMES_URL || this._reactH5Url;
    this._sportsUrl =
      ENV_CONFIG.REACT_APP_API_SPORTS_URL || ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._quick3dUrl =
      ENV_CONFIG.REACT_APP_API_QUICK_URL || ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._racecarUrl =
      ENV_CONFIG.REACT_APP_API_RACECAR_URL || ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._lottoUrl =
      ENV_CONFIG.REACT_APP_API_LOTTO_URL || ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._stateUrl =
      ENV_CONFIG.REACT_APP_API_STATE_URL || ENV_CONFIG.REACT_APP_API_BASE_URL;
    this._moengageAppId = this._moengageAppId;
    this._codePushKey =
      ENV_CONFIG.REACT_APP_ENV === 'prod'
        ? '5naQjnOyKbgw_rtH5Wel30umaDgYv2qxoNvCV'
        : ENV_CONFIG.REACT_APP_ENV === 'uat'
        ? 'eij_AQ1ONgfae30PHCTkTKl4sSiUNfX_8oOUv'
        : ENV_CONFIG.REACT_APP_ENV === 'test'
        ? 'qvLFQgxtWE5xfTxeqPs8FlBPSRgc9LKqgRmq3'
        : '';
    this._IMURL = ENV_CONFIG.REACT_APP_IM_URL || '';
    this._betbyScriptUrl = ENV_CONFIG.REACT_APP_BETBY_SCRIPT_URL || '';
    this._imApiUrl =
      ENV_CONFIG.REACT_APP_API_IM_URL ||
      ENV_CONFIG.REACT_APP_API_BASE_URL ||
      '';
    this._gr8IframeUrl = ENV_CONFIG.REACT_APP_GR8_IFRAME_URL || '';
  }
}

export const envConfig = new Config();
export default envConfig;
